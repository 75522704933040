<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList' />
        <el-table :data="page.list" border v-loading='listLoading' height="350">
            <el-table-column prop="selection" :label="$t('currencySetNft.text8')" width="100" >
                <template #default="scope">
                    <el-checkbox size="large" @change="changeFun(scope.row)" v-model='checkItem'/>
                </template>
            </el-table-column>
            <el-table-column prop="chain" :label="$t('currencySetNft.text6')" width="100" />
            <el-table-column prop="protocol" :label="$t('currencySetNft.text7')" width="100" />
            <el-table-column prop="walletName" :label="$t('currencySetNft.name4')" width="150" />
            <el-table-column prop="walletAddress" :label="$t('otherWallatNft.text6')" />
        </el-table>
        <pageCom :page='page' @sizeChange='sizeChange' @currentChange='currentChange'/>
    </div>
</template>
<script setup>
    import { ref,defineEmits,defineExpose } from 'vue'
    import mixins from '@/views/mixins/page'
    import fromSearch from '@/components/fromSearch.vue'
    import { addrSearch } from '@/const/from/nft/hotWallet'
    import pageCom from '@/components/pageCom.vue'
    
    const emit = defineEmits(['getCoin'])
    const object_ = ref({});object_.value = addrSearch
    let pageObj = mixins(['getAssetswalletinfoList'])
    const {page,listLoading,sizeChange,currentChange,getList,updateOtherParm} = pageObj
    const checkItem = ref(false)

    const changeFun = (e)=>{
        emit('getCoin',e)
    }
    const init_ = (obj)=>{
        checkItem.value = false
        updateOtherParm({walletType:4,...obj})
        getList()
    }
    defineExpose({init_})
</script>

<style lang="scss" scoped>
</style>